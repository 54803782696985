.text-input-form {
  margin-top: 10vh;
}

.question-text-container {
  margin-top: 16px;
  width: 90%;
  min-height: 12vh;
  border-radius: 8px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
}

.input-container {
  position: relative;
  top: -5vh;
  width: 100%;
  min-height: 50vh;
  padding: 10vh 16px 16px 16px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
}

.multiple-button-container {
  flex: 1 1 60%;
  width: 100%;
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.image-container {
  max-width: 100%;
  max-height: 100%;
}

Button .image-container,
Button .illustration-image {
  max-height: 20vh;
}

.illustration-image {
  max-height: 20vh;
  max-width: 100%;
}

.big-text {
  width: 100%;
  height: 30vh;
  font-size: 3vh;
  padding: 2vh;
}

.not-column {
  flex-direction: row;
}

/*WHERE IN PICTURE*/

.where-in-picture-img {
  width: auto;
}

@media only screen and (max-width: 500px) {
  .where-in-picture-img {
    height: 350px;
  }
}

@media only screen and (min-width: 501px) {
  .where-in-picture-img {
    height: 500px;
  }
}

.incorrect-image:active {
  animation: shake 0.1s;
  animation-iteration-count: 2;
}

.hidden-image {
  display: none;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
/* -------------- */

/*Login-task*/
#name,
#password,
#username {
  font-size: 3vh;
  font-weight: normal;
}

.input-label {
  display: inline;
  margin-right: 8px;
  font-size: 3vh;
  font-weight: normal;
  color: white;
}

.question-details {
  margin-bottom: 5vh;
  text-align: left;
  font-size: 3vh;
}

.login {
  padding: 4vh 1vh;
  padding-bottom: 0;
  border-radius: 8px;
}

.input-field {
  margin-bottom: 3vh;
  width: 50vmin;
  border-radius: 3vmin;
  text-align: center;
}

.feedback {
  margin-bottom: 5vh;
}

.feedback-incorrect {
  color: #ff5722;
}

.feedback-correct {
  color: #0e4909;
}

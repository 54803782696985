.username {
  color: white;
  font-size: 3vh;
}

.subject-container {
  position: relative;
  top: -5vh;
  width: 100%;
  min-height: 60vh;
  max-height: 80vh;
  padding-top: 10vh;
  padding-bottom: 16px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border-radius: 3vh;
}

.pause-exit-container {
  background-color: #e2e2e2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}

.exit-btn-container {
  height: 10vh;
  padding-left: 1.4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.choice-content {
  width: 80%;
  max-width: 390px;
  min-height: 35vh;
  padding: 2vmin;
  margin: 10% auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.close {
  height: 11vh;
  width: 11vh;
  font-size: 10vh;
  text-align: center;
  cursor: pointer;
}

.choice-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.choice-title {
  margin: 3vh auto;
  font-size: 1.6em;
}

.choice-details-container {
  margin-bottom: 4vh;
}

.choice-button-container {
  margin: 2vh;
  padding-bottom: 2vh;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.choice-btn {
  padding: 16px 13px;
  margin: 8px;
  font-size: 2.6vh;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  filter: none;
}

.choice-btn:hover {
  filter: brightness(95%);
}
.choice-btn:active {
  box-shadow: none;
}

.close-btn {
  background: #78f89f;
}

.close-btn:hover {
  background: #57b322;
}

.exit-btn:hover {
  background: #fc5537;
}

.pause-btn {
  background: #f6f878;
}

.pause-btn:hover {
  background: #d8db29;
}

/* -- What units-question -- */
.image-with-description {
  padding: 0;
  flex: 1 1 50%;
  max-width: 50%;
}

.unit-btn {
  margin: 2min;
  width: 95%;
  flex-direction: column;
  align-items: center;
}

.unit-name {
  margin-top: 0;
  font-size: 1em;
}

.unit-img {
  height: 10vmin;
  width: 10vmin;
}

/* ----------- */

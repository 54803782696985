.btn {
  cursor: pointer;
}

.regular-btn {
  padding: 2.6vh 8vw;
  margin: 1vmin;
  text-align: center;
  border-radius: 8px;
  font-size: 2.6vh;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.btn:hover {
  filter: brightness(80%);
}

.btn:active {
  box-shadow: none;
}

.multi-btn:hover {
  filter: none;
  background-color: #acdae0;
}

.multi-btn.selected {
  box-shadow: none;
  background-color: #73d0dc;
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

/*NEXT page*/

.next-button {
  background-color: #ffc107;
}

/*EXIT button in navbar*/

.nav-btn {
  background-color: transparent;
  color: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}

.nav-btn:hover {
  filter: invert(55%) sepia(55%) saturate(4482%) hue-rotate(161deg)
    brightness(94%) contrast(105%);
}

.nav-btn:active {
  background-color: transparent;
}

.nav-img {
  margin: auto;
  height: inherit;
  width: inherit;
}

/*SUBJECT BUTTON in overview*/
.subject-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2vh;
  width: 85%;
  min-height: 12vh;
  margin: 0.5vh auto;
  padding: 1vh 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.subject-btn .subject-title-container {
  width: 75%;
}

.subject-title-container p {
  position: relative;
  top: -8px;
  text-align: left;
  font-size: 2.7vh;
  font-weight: normal;
  color: white;
}

.subject-btn:disabled {
  color: black;
  background-color: #e2e2e2;
}

.buttons-underneath-container .rowbtn {
  flex: 1 1 30px;
}

/* MULTIPLE BUTTONS */
.answer-btn {
  flex: 1 1 96%;
  max-width: 96%;
  font-size: 16px;
  margin: 1%;
  min-height: 7vh;
  max-height: 30vh;
  font-size: 20px;
  padding: 2vmin;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 501px) {
  .answer-btn {
    flex: 1 1 48%;
    max-width: 48%;
    min-height: 10vh;
    max-height: 25vh;
    font-size: 18px;
    flex-direction: row;
    align-items: center;
    justify-items: center;
  }
}

/* --------------- */

.download {
  margin: 2vh;
  max-height: 10vh;
  padding: 2vmin;
}

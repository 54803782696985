.progress-circle-container {
  height: 4.5em;
  width: 4.5em;
  position: relative;
  text-align: center;
}

.progress-background {
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  line-height: 100%;
}

/* Text inside the control */
.progress-circle-container span {
  font-size: 1.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #53777a;
  z-index: 1;
}

* {
  box-sizing: border-box;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

body {
  background-color: #e2e2e2;
}

.content {
  position: relative;
  top: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  min-height: 75vh;
  width: 95vw;
}

@media only screen and (min-width: 900px) {
  .content {
    max-width: 60vw;
  }
}

.h1 {
  margin-bottom: 1vh;
  font-size: 4vh;
}

.h2 {
  font-size: 3vh;
}

.p {
  font-size: 1.5em;
}

.white {
  color: white;
}

.white-background {
  background-color: #f9f9f9;
}

.black {
  color: #212121;
}

.black-background {
  background-color: #212121;
}

.grey-background {
  background-color: #9e9e9e;
}

/*Brukes i spørsmålsbokser før temaoversikt*/
.dark-grey-background {
  background-color: #717171;
}

.bluegrey-background {
  background-color: #60768b;
}

/*neste-knapp*/
.amber-background {
  background-color: #ffc107;
}

.green-background {
  background-color: #259b24;
}

/*temafarger under her*/
.light-green-background {
  background-color: #8bc34a;
}

.lime-background {
  background-color: #cddc39;
}

.yellow-background {
  background-color: #ffeb3b;
}

.orange-background {
  background-color: #ff9800;
}

.deep-orange-background {
  background-color: #ff5722;
}

.red-background {
  background-color: #e51c23;
}

.pink-background {
  background-color: #e91e63;
}

.purple-background {
  background-color: #9c27b0;
}

.indigo-background {
  background-color: #3f51b5;
}

.blue-background {
  background-color: #5677fc;
}

.light-blue-background {
  background-color: #03a9f4;
}

.cyan-background {
  background-color: #00bcd4;
}

.deep-purple-background {
  background-color: #673ab7;
}

.teal-background {
  background-color: #009688;
}

.transparent {
  background-color: transparent;
}

.light-font {
  font-weight: light;
}

.normal-font {
  font-weight: normal;
}

.bold-font {
  font-weight: bold;
}
